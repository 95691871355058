import clsx from 'clsx';
import React, { useContext, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { EmbedContext } from '../../embed-context';
import { PurchaseFormData, Region } from './purchase-api';
import { usePurchaseSubmit } from './usePurchaseSubmit';

export function PurchaseForm({ region, partnership }: { region: Region, partnership?: string }) {
  const [submissionFailed, setSubmissionFailed] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const { register, handleSubmit, setValue, watch } = useForm<PurchaseFormData>();
  const { url } = useContext(EmbedContext);
  const submitHandler = usePurchaseSubmit();

  const isCompanyNameTooLong = watch('organizationName')?.length > 32;

  const onSubmit: SubmitHandler<PurchaseFormData> = async (data) => {
    setSubmissionFailed(false);
    setSubmitting(true);
    await submitHandler(region, data, url, partnership).then((result) => {
      if (result && result.url) {
        if (window.self !== window.top) {
          window.parent.postMessage({ url: result.url }, url.origin.toString());
        } else {
          window.location.href = result.url;
        }
      }
    }).catch((err) => {
      setSubmissionFailed(true);
      // TODO: Can we do anything with a fail?
      console.error('Failed to initiate purchase flow', { err });
    }).finally(() =>  setSubmitting(false));
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(url.search);
    const fields = ['firstName', 'lastName', 'email', 'organizationName', 'taxTeamSize'];
    fields.forEach((field) => {
      const value = urlParams.get(field);
      if (value) {
        setValue(field as keyof PurchaseFormData, value);
      }
    });
  }, [setValue, url]);

  const inputClasses = [
    "border",
    "border-neutralGrey",
    "bg-offWhite",
    "placeholder-otherWhite",
    "mb-8",
    "rounded-sm",
    "min-w-full",
    "max-w-full"
  ];

  const labelClasses = [
    'mb-0.5',
  ];

  const baseUrl = `https://www.bluej.com${region === 'ca' ? '/ca' : ''}`;

  return (
    <>
      { submissionFailed && (
        <div className="p-2 mb-4 bg-red-100 border border-red-500">
          Submission Failed
        </div>
      )}
      <div data-embed-url={url} data-embed-partner={partnership} data-embed-region={region}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-6">
            <div className="flex md:gap-6 flex-col md:flex-row">
              <div className="flex-1">
                <div>
                  <label htmlFor="firstName" className={clsx(labelClasses)}>
                    First name
                    <span className="text-red">&nbsp;*</span>
                  </label>
                </div>
                <div>
                  <input
                    id="firstName"
                    className={clsx(inputClasses)}
                    placeholder="First name"
                    type="text"
                    {...register('firstName', { required: true })}
                  />
                </div>
              </div>
              <div className="flex-1">
                <div>
                  <label htmlFor="lastName" className={clsx(labelClasses)}>
                    Last name
                    <span className="text-red">&nbsp;*</span>
                  </label>
                </div>
                <div>
                  <input
                    id="lastName"
                    className={clsx(inputClasses)}
                    placeholder="Last name"
                    type="text"
                    {...register('lastName', { required: true })}
                  />
                </div>
              </div>
            </div>
            <div>
              <div>
                <label htmlFor="email" className={clsx(labelClasses)}>
                  Email
                  <span className="text-red">&nbsp;*</span>
                </label>
              </div>
              <div>
                <input
                  className={clsx(inputClasses)}
                  placeholder="Email"
                  id="email"
                  type="email"
                  {...register('email', { required: true })} />
              </div>
            </div>
            <div className="mb-8">
              <div>
                <label htmlFor="organizationName" className={clsx(labelClasses)}>
                  Company name
                  <span className="text-red">&nbsp;*</span>
                </label>
              </div>
              <div>
                <input
                  id="organizationName"
                  className={clsx(
                    inputClasses.filter(cls => !cls.startsWith('mb-')),
                    isCompanyNameTooLong && 'outline outline-1 outline-red'
                  )}
                  placeholder="Company name"
                  type="text"
                  {...register('organizationName', { required: true, maxLength: 32 })}
                />
                {isCompanyNameTooLong && (
                  <div className="text-red text-sm mt-1 mb-8">Company name cannot exceed 32 characters.</div>
                )}
              </div>
            </div>
            <div>
              <div>
                <label htmlFor="taxTeamSize" className={clsx(labelClasses)}>
                  How many people are on your company's tax team?
                  <span className="text-red">&nbsp;*</span>
                </label>
              </div>
              <div>
                <select
                  id="taxTeamSize"
                  required
                  className={clsx(inputClasses, 'invalid:text-otherWhite [&>option:not([value=""])]:text-black')}
                  defaultValue=""
                  {...register('taxTeamSize', { required: true })}
                >
                  <option value="" disabled>Please Select</option>
                  <option value="Sole Practitioner">Sole Practitioner</option>
                  <option value="2 - 10">2 - 10</option>
                  <option value="11 - 75">11 - 75</option>
                  <option value="76+">76+</option>
                </select>
              </div>
            </div>
            <div className="mb-4">
              <div>
                <input type="checkbox" className="inline-block mr-2"
                       id="terms"
                       value="agree" {...register('terms', { required: true })} />
                <label htmlFor="terms">
                  I have read and agree to the Blue J <a href={`${baseUrl}/subscriber-agreement`}
                                                         rel="noreferrer noopener" target="_blank">Subscriber
                  Agreement</a>, <a href={`${baseUrl}/terms-of-use`} rel="noreferrer noopener" target="_blank">Terms
                  of Use</a>, and <a href={`${baseUrl}/privacy-notice`} rel="noreferrer noopener"
                                     target="_blank">Privacy Notice</a>.
                  <span className="text-red">&nbsp;*</span>
                </label>
              </div>
            </div>
          </div>
          <div>
            <button type="submit"
                    disabled={submitting}
                    className="font-semibold bg-cabaret px-8 py-3 hover:bg-hoverRed transition-colors rounded-lg text-black disabled:bg-raven">Buy
              Now
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
