import React from 'react';
import { Navigate } from 'react-router-dom';

import { sentryCreateBrowserRouter } from './modules/core/sentry/utils';
import { LandingRoute } from './modules/purchase/LandingRoute';
import { NotFoundRoute } from './modules/purchase/NotFoundRoute';

export function createRouter(isEmbedded: boolean) {
  return sentryCreateBrowserRouter([
    {
      path: '/',
      element: <LandingRoute region="us" />
    },
    {
      path: '/ca',
      element: <LandingRoute region="ca" />
    },
    {
      path: '/partnerships',
      children: [
        {
          index: true,
          element: <Navigate to="/" replace />
        },
        {
          path: 'cpacanada',
          element: <LandingRoute region="ca" partnership="CPA Canada" />
        },
        {
          path: 'natp',
          element: <LandingRoute region="us" partnership="NATP" />
        },
        {
          path: 'cpacom',
          element: <LandingRoute region="us" partnership="CPA.com" />
        },
        {
          path: 'macpa',
          element: <LandingRoute region="us" partnership="MACPA" />
        },
        {
          path: 'msatp',
          element: <LandingRoute region="us" partnership="MSATP" />
        },
        {
          path: 'nccpap',
          element: <LandingRoute region="us" partnership="NCCPAP" />
        }
      ]
    },
    ...(isEmbedded
      ? [
        {
          path: '404-page',
          element: <NotFoundRoute />
        }
      ]
      : []),
    {
      path: '*',
      element: <Navigate to={isEmbedded ? '404-page' : '/'} replace />
    }
  ], { basename: isEmbedded ? '/embed' : '' });
}
