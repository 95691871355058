import React from "react";

import { useConfigContext } from "../config/ConfigContext";

export function useRecaptcha() {
  const { recaptcha } = useConfigContext();

  const getFormSubmitRecaptchaToken = React.useCallback(() => {
    return getRecaptchaActionToken(recaptcha, 'signup');
  }, [recaptcha]);

  return {
    getFormSubmitRecaptchaToken
  }
}

function getRecaptchaActionToken(key: string, action: string): Promise<string> {
  return new Promise((resolve) => {
    if (window.grecaptcha) {
      window.grecaptcha.ready(async () => {
        const token = await window.grecaptcha.execute(key, { action });
        resolve(token);
      });
    } else {
      resolve('');
    }
  });
}